.arrange {
  font-family: "Hind", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 27px;
  color: #707070;
  padding: 0 0 0 15px;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    text-shadow: 0 0 0.65px #1ebd4c, 0 0 0.65px #1ebd4c;
    color: #1ebd4c;
  }
}

.li {
  margin-left: 0.9rem;
}

.call {
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 0px;
gap: 4px;

font-family: 'Hind';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 120%;
/* identical to box height, or 17px */
text-transform: uppercase;
/* Blue */
color: #008AE0;
text-wrap: nowrap;
}

.call_gray {
color: #707070;
}

.pointer {
  cursor: pointer;
}

.my-account {
  margin-left: 0.3rem;
  text-transform: none;
  color: #202020;
  letter-spacing: 0px;
}

.sv-header-phone-btn {
  display: none;
}

.sv-contact-number {
  font-size: 24px!important;
  font-weight: 600!important;
}

@media (max-width: 1200px) {
  .my-account {
    display: none;
  }
}

@media (max-width: 500px) {
  .my-account-btn {
    display: none;
  }
  .sv-language-btn {
    display: none;
  }
}

@media (max-width: 970px) {
  .sv-header-phone {
    display: none;
  }
  .sv-header-phone-btn {
    display: unset;
  }
}
