@import '../../../styles/scss/abstracts/figmaVariables';

.sv-smart-button {
  height: 56px,
}
@media (max-width: 576px) {
  .sv-smart-button {
    height: 40px;
    padding: 8px 10px !important;
  }

  .filterDot {
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: $secondary-500;
    align-self: start;
    position: absolute;
    right: 8px;
    animation: changeBackground 3s infinite;
  }

  @keyframes changeBackground {
    50% { background-color: $secondary-200; }
}

}